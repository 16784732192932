import React, { Component } from 'react';
import "startbootstrap-simple-sidebar/css/simple-sidebar.css"
import "./SidebarMenu.css"
import Logo from "./Logo";

import SidebarItem from "./SidebarItem";

export class SidebarMenu extends Component {

    render () {
        return (
            <div className="row" id={"sidebar-outer"}>
                <div className={"col pr-0"}>
                    <div className="border-right shadow" id="sidebar-wrapper">
                        <div className="sidebar-heading p-0">
                            <Logo/>
                        </div>
                        <div className="list-group list-group-flush">
                            <SidebarItem buttonId={"sidebar-link-overview"} tooltipPlacement={"right"} faIcon={"map"} link={"/"} tooltipText={"Live Map"} onItemClick={this.collapseSidebar} />
                            {/*<SidebarItem buttonId={"sidebar-link-scouting"} tooltipPlacement={"right"} faIcon={"flag"} tooltipText={"Scouting (Coming soon)"} />*/}
                            {/*<SidebarItem buttonId={"sidebar-link-reporting"} tooltipPlacement={"right"} faIcon={"chart-bar"} tooltipText={"Reporting (Coming soon)"} />*/}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
