import React, { useState } from 'react';
import C3Chart from 'react-c3js';
import 'c3/c3.css';

export default function ({barchartData,sHeight, locateDevice}) {
    const [height, setHeight] = useState(sHeight);

    const axis= {
        x: {
            type: 'category',
            tick: {
                centered: true
            }
        },

        y: {
            show: true,
            tick: {
                count: 5,
                format: function (d) {
                    return d.toFixed(2);
                }
            }
        },
        rotated: true
    }

         let size= {
         height: height
    }


    const bar = {
        space: 0.2
    }

    const data = {
        x: 'Device',
        onclick: function(d, element) {
            locateDevice(barchartData.ids[d.index]);
        },
        columns: barchartData.data,
        type: 'bar',
        colors: {
            Hectares: function(d) {
                return barchartData.colors[d.index] ?? "#afafaf";
            },
            Speed: function(d) {
                return barchartData.colors[d.index] ?? "#afafaf";
            }
            ,
        },
    };

    return (
        <C3Chart size={size} data={data} labels={true} axis={axis} bar={bar} legend={{show: false}} onresized={() => adjustHeight()} oninit={() => adjustHeight()}  />
    );
}

function adjustHeight(){
    try {
        //grab a copy of the c3 element
            let c3 = document.querySelector(".c3");
        //grab its parents height
            let height = c3.parentElement.offsetHeight;
            //set the c3 height and max height as the parents height
            c3.style.maxHeight = height + "px";
           c3.style.height = height + "px";
    }catch (e){
        //any issues, cry for help
        console.error("[IAG-ERROR] Could not set height of chart: " + e.message);
    }
}