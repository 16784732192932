import React from "react";
import {InputGroup, InputGroupAddon, InputGroupText} from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './SearchBox.css'

const SearchBox = props => {
    const { persistentItems, setFilterableItems, filterOn, enabled } = props;

    const searchItems = (v) => {
        setFilterableItems( (v === "" ? persistentItems : persistentItems.filter(function(i) {
            //loop through all filterable items in the filkterOn array
            for (let k = 0; k < filterOn.length; k++) {
                //split each filterable item into its property parts
                let parts = filterOn[k].split(".");
                //grab length for speed
                let length = parts.length;
                //take a copy of i into property
                let property = i;
                //loop parts length
                for (let j = 0; j < length; j++) {
                    //grabbing each current property item on the next
                    property = property[parts[j]];
                }
                //check the result isnt null, undefined and does match our reqs
                if( typeof property !== "undefined" && property !== null && property.toLowerCase().includes(v.toLowerCase()) ){
                    //if so, return true because we found a match
                    return true;
                }
            }
            //otherwise, return false finished all filterable array items and found no match
            return false;
        })) );
    }

    return (
        <InputGroup className={"searchBox mt-2"}>
            <InputGroupAddon addonType="prepend">
                <InputGroupText><FontAwesomeIcon icon={"search"} /></InputGroupText>
            </InputGroupAddon>
            <input className={"form-control"} type={"text"} placeholder="Search devices" onClick={(e) => e.currentTarget.select()} onChange={(e) => searchItems(e.target.value)} disabled={!enabled} />
        </InputGroup>
    );
};

export default SearchBox;