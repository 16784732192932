import React, { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import "./DeviceTrailDropdown.css"

export default function ({setCurrentDuration,reloadDevices,enabled}) {
   // let SelectedDuration=0;
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [DropdownValue, setDropdownValue] = useState(0);
    const toggle = () => setDropdownOpen(prevState => !prevState);

    //static Dropdown values
    const deviceTrailDropdown = [{key:"1", value:"Last 1 hour"},{key:"3", value:"Last 3 hours"},{key:"6", value:"Last 6 hours"},{key:"12", value:"Last 12 hours"}];
    //Onclick function for the dropdown items
    function setDeviceTrails(selected,key) {
        //set the dropdown value
        setDropdownValue(selected);
        //set state for the current duration in Map.js for the intermittent reload
        setCurrentDuration(key);
        //call the reload func to reload the map
        reloadDevices();
    }

    return (
        <Dropdown  isOpen={dropdownOpen} toggle={toggle} disabled={!enabled}  className={"deviceTrailDropdown"}>
            <DropdownToggle className={"btn btn-light btn-sm dropdown-toggle mt-1" } caret>
                {deviceTrailDropdown[DropdownValue].value}
            </DropdownToggle>
            <DropdownMenu>
                {
                    deviceTrailDropdown.map((deviceTrail, index) =>
                        <DropdownItem
                            key={index}
                            onClick={() => setDeviceTrails(index, deviceTrail.key)}>{deviceTrail.value}</DropdownItem>
                    )
                }
            </DropdownMenu>
        </Dropdown>
    );
}
