import React from 'react';
import { Alert, Button, Container, Row, Col } from 'reactstrap';

const ErrorAlert = ({message, actionMethod, actionDisplayName}) => {
    return (
        <Container className={"pt-3"}>
            <Row>
                <Col>
                    <Alert color="danger">
                        <h4 className="alert-heading">We ran into an error</h4>
                        <p>
                            {message}
                        </p>
                        {actionMethod ?
                            <div><hr /><Button color="danger" onClick={actionMethod}>{actionDisplayName}</Button></div>
                        : ""}
                    </Alert>
                </Col>
            </Row>
        </Container>
    );
};

export default ErrorAlert;