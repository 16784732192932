import './scss/custom.scss';
import React from 'react';
import ReactDOM from 'react-dom';

//import msal tools
import { PublicClientApplication, EventType } from "@azure/msal-browser";

//import the router and app
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
//@todo uncomment for serviceWorker
//import registerServiceWorker from './registerServiceWorker';

//import msalConfig
import { msalConfig } from "./authConfig";
//create new PCA instance
export const pca = new PublicClientApplication(msalConfig);

//grab all accounts
const accounts = pca.getAllAccounts();
//check if one is available
if (accounts.length > 0) {
    //if so, select the first in the list (may need to adjust in future if we offer a selector)
    pca.setActiveAccount(accounts[0]);
}
//add a pca event callback
pca.addEventCallback((event) => {
    //checking for login success event and event
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
        //and setting active account
        const account = event.payload.account;
        pca.setActiveAccount(account);
    }
});

//grab base url
const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
//and root element
const rootElement = document.getElementById('root');

//render the app
ReactDOM.render(
    <React.StrictMode>
      <Router basename={baseUrl}>
          <App pca={pca} />
      </Router>
    </React.StrictMode>,
  rootElement);

// @todo Uncomment the line above that imports the registerServiceWorker function
// and the line below to register the generated service worker.
// By default create-react-app includes a service worker to improve the
// performance of the application by caching static assets. This service
// worker can interfere with the Identity UI, so it is
// disabled by default when Identity is being used.
//
//registerServiceWorker();

