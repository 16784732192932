import React from 'react';
import {Card, CardText, CardTitle, UncontrolledTooltip} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import './DeviceCard.css';
import {timeSince} from "../helpers/Utilities";

export default function ({device, locateDevice, editable, editOnClickHandler, cardIndex,activityType}) {
    const buttonId = `card-edit-button-${cardIndex}-${device.name}`;
    let averageSpeedKmh="";
    if(device.properties.lastSpeed!=null)
     averageSpeedKmh = ', '+device.properties.lastSpeed.toFixed(2)+ 'km/h';


    const faultItems  = device.properties.deviceFaults.map((fault) =>
        <li>
            {fault}
        </li>
    );
    return (
        <Card id={"deviceCard" +device.name} body className={"mb-2 mt-2 pointer device-card " + (device.properties.selected ? "device-selected" : "")}
              style={{borderLeftWidth: 3, borderLeftColor: device.properties.color, borderLeftStyle: 'solid'}}
              onClick={() => {
                  locateDevice(device.name);
              }}>
            <CardTitle tag="h5">
                {typeof device.properties.displayName !== "undefined" && device.properties.displayName !== null && device.properties.displayName.length > 0
                    ? device.properties.displayName
                    : device.name
                }
                <div><small>{device.name}</small></div>
            </CardTitle>
            <CardText>

                {//condition to check if there are any faults in GPS
                    device.properties.deviceFaults.length>0
                    ?<div>
                        <span>
                                <FontAwesomeIcon icon={"circle"} className={"fa-fault"}/> online, with fault
                     </span>

                     </div>
                    :device.properties.online === true
                        ? <span>
                        <FontAwesomeIcon icon={"circle"}
                                         className={"fa-online"}/> online{averageSpeedKmh}
                        </span>

                        :device.properties.lastPointUpdate !== null
                            ?<span><FontAwesomeIcon icon={"circle"}
                                                    className={"fa-stale"}/> seen {timeSince(new Date(device.properties.lastPointUpdate))} ago
                             </span>
                            :<span><FontAwesomeIcon icon={"circle"} className={"fa-offline"}/> offline</span>
                }
                <br/>
                 {//displaying activity type for each device card
                     device.properties.activityType.map((activityTypeItem, index) =>
                         <span key={index}>
                            <span id={`device-card-pill-${device.name}-${index}`} className={"activity-type-badge-pill mr-1 badge badge-pill badge-primary"}  style={{backgroundColor: activityType[activityTypeItem].color}}> </span>
                             <UncontrolledTooltip placement="right" target={`device-card-pill-${device.name}-${index}`}>
                                 {activityType[activityTypeItem].name}
                             </UncontrolledTooltip>
                         </span>
                 )}



            </CardText>
            {
                //display tooltip message if there is any GPS device fault
                device.properties.deviceFaults.length>0
                    ?<UncontrolledTooltip className={"deviceCardTooltipClass"} placement="bottom" target={"deviceCard" +device.name}>
                        <dl className={"toolTipList"}>
                        <dt>Faults</dt>
                            {faultItems}
                        </dl>
                    </UncontrolledTooltip>:""
            }
            {editable === true
                ? <div>
                    <div id={buttonId} className={"card-edit-button-pencil"}
                         onClick={() => editOnClickHandler(device.name)}>
                        <div>edit</div>
                    </div>
                </div>
                : ""
            }
        </Card>
    );
}