import React, { useState } from 'react';
import {Dropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap';
import "./FarmsDropdown.css"

const FarmsDropdown = ({farmsData, activeFarm, setActiveFarm}) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggle = () => setDropdownOpen(prevState => !prevState);

    return (
        <Dropdown isOpen={dropdownOpen} toggle={toggle} className={"farmsDropdown text-right"}>
            <DropdownToggle color={"primary"} caret>
                {typeof activeFarm !== "undefined" && typeof farmsData[activeFarm] !== "undefined" ? farmsData[activeFarm].name : "Select your farm"}
            </DropdownToggle>
            <DropdownMenu right>
                {farmsData.map((farm, index) =>
                    <DropdownItem key={index} onClick={() => setActiveFarm(index)}>{farm.name}</DropdownItem>
                )}
            </DropdownMenu>
        </Dropdown>
    );
}

export default FarmsDropdown;