import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarToggler } from 'reactstrap';
import './NavMenu.css';
import { MsalContext } from "@azure/msal-react";
import UserDropdown from "./UserDropdown";
import FarmsDropdown from "./FarmsDropdown";
import TenantsDropdown from "./TenantsDropdown";
import PreReleaseNavItem from "./PreReleaseNavItem";
import PrereleaseOnlyComponent from "./PrereleaseOnlyComponent";

export class NavMenu extends Component {
  static displayName = NavMenu.name;
  static contextType = MsalContext;

  constructor (props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true
    };
  }

  toggleNavbar () {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  render () {
    return (
        <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow" light>
          <Container fluid={true} className={"pr-0 pl-0"}>
            {/*<NavbarBrand tag={Link} to="/">react_webui</NavbarBrand>*/}
            {/*<Button outline color="primary">Collapse</Button>{' '}*/}
            <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
            <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
              <UserDropdown />
              {this.props.farmsData ? <> <FarmsDropdown farmsData={this.props.farmsData} activeFarm={this.props.activeFarm} setActiveFarm={this.props.setActiveFarm} /> {/*<NotificationsDropdown />*/} </> : ""}
              { this.props.tenantsData
                  ? this.props.tenantsData.length>1
                      ? <> <TenantsDropdown tenantsData={this.props.tenantsData}
                                                     activeTenant={this.props.activeTenant}
                                                     setActiveTenant={this.props.setActiveTenant}
                                            setActiveFarm={this.props.setActiveFarm} /> </>
                      : ""
                  :""
                }
                <PrereleaseOnlyComponent prereleaseComponent={<PreReleaseNavItem/>}/>
            </Collapse>
          </Container>
        </Navbar>
    );
  }
}
