

// Config object to be passed to Msal on creation
export const apiConfig = {
    endpoints: {
        baseUrl: process.env.REACT_APP_FARM_API_BASE_URL,
        tenants: {
            getAll: "tenants",
        },
        farms: {
            get: "Farm/{id}",
            getAll: "Farms",
        },
        notifications: {
            getAll: "Notifications",
        },
        devices: {
            getAll: "Sensors",
            patchDisplayName: "Sensors/{id}/DisplayName"
        },
        assetTrackerLogs: {
            getAll: "AssetTrackerLogs",
        },
        getHectareCovered:{
            getAll: "AssetTrackerLogs/GetHectareCovered",
        }
    }
};