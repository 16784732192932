import React, { Component } from 'react';
import mapboxgl from 'mapbox-gl/dist/mapbox-gl-csp';
// eslint-disable-next-line import/no-webpack-loader-syntax
import MapboxWorker from 'worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker';
import 'mapbox-gl/dist/mapbox-gl.css';
import "./Map.css"
import {apiConfig} from "../apiConfig";
import withAxios from "react-axios/lib/components/withAxios";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    Button,
    Col,
    Form,
    FormGroup, Input,
    Label, Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Tooltip
} from "reactstrap";
import {timeSince} from "../helpers/Utilities";
import DeviceCard from "./DeviceCard";
import SearchBox from "./SearchBox";
import DeviceTrailDropdown from "./DeviceTrailDropdown";
import TogglePanel from "./TogglePanel";
import TodaysMetricChartsTab from "./TodaysMetricChartsTab"
export default withAxios(class MapRaw extends Component {

    constructor(props) {
        super(props);
        mapboxgl.workerClass = MapboxWorker;
        mapboxgl.accessToken = 'pk.eyJ1IjoiYXNobGV5dy1pYWciLCJhIjoiY2tpNTNnYzVyMDhoNzJwcW82aDFjNWlkbiJ9.IjtGm0oXevNjSqm9WIQKNg';
        this.state = {
            lng: 145.5586249,
            lat: -37.85194,
            zoom: 17,
            sidebarDetailCollapsed: true,
            togglePanelCollapsed: true,
            featuresDataset: [],
            alreadyInitd: false,
            devicesError: null,
            map: null,
            tooltipReloadToggled: false,
            reloadButtonEnabled: false,
            editDeviceModalIsOpen: false,
            selectedDeviceIndex: null,
            savingDevice: false,
            mapLoaded: false,
            longTermFeaturesDataset: [],
            currentDuration:1, //for storing the state of the current duration
            activityTypeArray : [{name :"Not Specified",color : this.getRandomColor(0,4).hsl,include: true }]
        };

        this.mapContainer = React.createRef();
        //bind correct context to these functions
        this.toggleSidebar = this.toggleSidebar.bind(this);
        this.toggleTogglePanel = this.toggleTogglePanel.bind(this);
        this.collapseSidebar = this.collapseSidebar.bind(this);
        this.openSidebar = this.openSidebar.bind(this);
        this.locateDevice = this.locateDevice.bind(this);
        this.loadActiveDevices = this.loadActiveDevices.bind(this);
        this.reloadDevices = this.reloadDevices.bind(this);
        this.toggleReloadButton = this.toggleReloadButton.bind(this);
        this.intervalReload = this.intervalReload.bind(this);
        this.setFeaturesDatasetStateForSearch = this.setFeaturesDatasetStateForSearch.bind(this);
        this.setFeaturesDatasetStateForSearchButtons = this.setFeaturesDatasetStateForSearchButtons.bind(this);
        this.reloadButtonEnabled = this.reloadButtonEnabled.bind(this);
        this.setEditDeviceModalIsOpen = this.setEditDeviceModalIsOpen.bind(this);
        this.patchDeviceDisplayName = this.patchDeviceDisplayName.bind(this);
        this.editDevice = this.editDevice.bind(this);
        this.setCurrentDuration=this.setCurrentDuration.bind(this);

    }
    setCurrentDuration(duration) {
        this.setState({currentDuration: duration});
    }


    setEditDeviceModalIsOpen(state) {
        this.setState({editDeviceModalIsOpen: state});
    }

    toggleSidebar() {
        this.setState({togglePanelCollapsed: true});
        this.setState({sidebarDetailCollapsed: !this.state.sidebarDetailCollapsed});
    }

    toggleTogglePanel() {
        this.setState({sidebarDetailCollapsed: true});
        this.setState({togglePanelCollapsed: !this.state.togglePanelCollapsed});
    }

    reloadButtonEnabled(enabled) {
        this.setState({reloadButtonEnabled: enabled});
    }

    toggleReloadButton() {
        this.setState({tooltipReloadToggled: !this.state.tooltipReloadToggled});
    }

    openSidebar() {
        this.setState({sidebarDetailCollapsed: false});
    }

    collapseSidebar() {
        this.setState({sidebarDetailCollapsed: true});
    }

    setFeaturesDatasetState(featuresDataset, callback = null, persistToLongTermDataset = true){
        this.setState({featuresDataset: featuresDataset}, callback);
        if(persistToLongTermDataset){
            this.setState({longTermFeaturesDataset: featuresDataset});
        }
    }

    setFeaturesDatasetStateForSearch(featuresDataset){
        this.clearSelectedDevice();
        this.setFeaturesDatasetState(featuresDataset, null, false);
    }

    setFeaturesDatasetStateForSearchButtons(e,index){
        this.clearSelectedDevice();
        let activityType=this.state.activityTypeArray;
        activityType[index].include = !(activityType[index].include)
        this.setState({activityTypeArray : activityType},()=>this.filterActivityType());
    }

    filterActivityType(){

        let filtered=[];
        for (let i = 0; i < this.state.longTermFeaturesDataset.length; i++) {
           //pushing activity types based on selection
            for (let j = 0; j < this.state.longTermFeaturesDataset[i].properties.activityType.length; j++) {
                if(this.state.activityTypeArray[this.state.longTermFeaturesDataset[i].properties.activityType[j]].include===true){
                    if (this.state.map.getLayer(`layer-with-pulsing-dot-${this.state.longTermFeaturesDataset[i].name}`))
                        this.state.map.setLayoutProperty(`layer-with-pulsing-dot-${this.state.longTermFeaturesDataset[i].name}`,'visibility','visible');
                    if (this.state.map.getLayer(`line-layer-${this.state.longTermFeaturesDataset[i].name}`))
                        this.state.map.setLayoutProperty(`line-layer-${this.state.longTermFeaturesDataset[i].name}`,'visibility','visible');

                    filtered.push(this.state.longTermFeaturesDataset[i]);
                    break;
                }
                else
                {
                    if (this.state.map.getLayer(`layer-with-pulsing-dot-${this.state.longTermFeaturesDataset[i].name}`))
                        this.state.map.setLayoutProperty(`layer-with-pulsing-dot-${this.state.longTermFeaturesDataset[i].name}`,'visibility','none');
                    if (this.state.map.getLayer(`line-layer-${this.state.longTermFeaturesDataset[i].name}`))
                        this.state.map.setLayoutProperty(`line-layer-${this.state.longTermFeaturesDataset[i].name}`,'visibility','none');
                }

            }

        };
        this.setState({featuresDataset: filtered});

    }

    getRandomColor(index, length, alpha) {
        let hue = Math.floor(index / length * 341); // between 0 and 340
        let saturation = 80;
        let lightness = 70;

        if (hue > 215 && hue < 265) {
            const gain = 20;
            let blueness = 1 - Math.abs(hue - 240) / 25;
            let change = Math.floor(gain * blueness);
            lightness += change;
            saturation -= change;
        }

        return {hsl: (typeof alpha !== "undefined" ? `hsl(${hue}, ${saturation}%, ${lightness}%, ${alpha})` : `hsl(${hue}, ${saturation}%, ${lightness}%)`), hex: this.convertHslToHex(hue, saturation, lightness)}
    }

    convertHslToHex(hue, saturation, lightness) {
        lightness /= 100;
        const a = saturation * Math.min(lightness, 1 - lightness) / 100;
        const f = n => {
            const k = (n + hue / 30) % 12;
            const color = lightness - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
            return Math.round(255 * color).toString(16).padStart(2, '0');   // convert to Hex and prefix "0" if needed
        };
        return `#${f(0)}${f(8)}${f(4)}`;
    }

    getDeviceIndexFromName(deviceName, searchInLongTermDataset = false) {
        let dataset = (searchInLongTermDataset ? this.state.longTermFeaturesDataset : this.state.featuresDataset);
        if (dataset !== null) {
            for (let i = 0; i < dataset.length; i++) {
                if (dataset[i].name.toLowerCase() === deviceName.toLowerCase()) {
                    return i;
                }
            }
        }
        return false;
    }

    editDevice(deviceName) {
        //this locates the devices and selects it
        this.locateDevice(deviceName);
        //this will then open the modal with the new details
        this.setEditDeviceModalIsOpen(true);
    }

    patchDeviceDisplayName(e) {
        //disable button
        this.setState({savingDevice: true});
        //check to see if the form exists
        if (document.getElementById("edit-device-form") !== null) {
            //grab displayname out
            let displayName = document.getElementById("display-name-input").value;
            //so a httpreq out to patch the display name
            this.props.axios.patch(apiConfig.endpoints.devices.patchDisplayName, {
                "id": document.getElementById("device-id").value,
                "display_name": displayName
            }).then(result => {
                //grab copy of featuredataset
                let tmp = this.state.featuresDataset;
                //update display name
                tmp[this.state.selectedDeviceIndex].properties.displayName = displayName;
                //update featuredataset with new var
                this.setState({featuresDataset: tmp});
                //close modal
                this.setState({editDeviceModalIsOpen: false});
            }).catch(error => {
                console.error("[IAG-ERROR] error collecting active devices: " + error.message)
            }).then(() => {
                //enable button
                this.setState({savingDevice: false});
            })
        }
    }

    locateDevice(deviceName) {
        let deviceIndex = this.getDeviceIndexFromName(deviceName);
        if (deviceIndex !== false) {
            //jump to location on map
            this.state.map.jumpTo({
                'center': this.state.featuresDataset[deviceIndex].geometry.coordinates[this.state.featuresDataset[deviceIndex].geometry.coordinates.length - 1],
                'zoom': 17
            });
            //select device
            this.selectDevice(deviceIndex);
        }
    }

    selectDevice(deviceIndex) {
        //clear current tracking item
        this.clearSelectedDevice();
        //set selectedIndex to null
        this.setState({selectedDeviceIndex: deviceIndex});
        //grab a copy of featuresDataset
        let tmp = this.state.featuresDataset;
        //set tracking to true
        tmp[deviceIndex].properties.selected = true;
        //update features dataset
        this.setFeaturesDatasetState(tmp, null, false);
        //close the sidebar
        if(!this.state.sidebarDetailCollapsed){
            this.toggleSidebar();
        }
    }

    clearSelectedDevice() {
        if (this.state.selectedDeviceIndex !== null) {
            //grab a copy of featuresDataset
            let tmp = this.state.featuresDataset;
            //set selected to false
            tmp[this.state.selectedDeviceIndex].properties.selected = false;
            //set selectedIndex to null
            this.setState({selectedDeviceIndex: null});
            //update features dataset
            this.setFeaturesDatasetState(tmp, null, false);
        }
    }

    loadActiveDevices() {
        if (this.props.farmsData !== null && this.props.activeFarm !== null) {
            if (this.state.mapLoaded) {
                this.retrieveActiveDeviceData(false);
            }else{
                this.retrieveActiveDeviceData(true);
            }
        }
    }

    retrieveActiveDeviceData(firstLoad) {
        this.props.axios({
            url: apiConfig.endpoints.assetTrackerLogs.getAll,
            params: {
                farmId: this.props.farmsData[this.props.activeFarm].id,
                duration: this.state.currentDuration
            }
        }).then(result => {
            //move data to a local var
            let activeDeviceData = result.data;
            //plot the data on a map
            this.plotDataOnMap(activeDeviceData, firstLoad);
        }).catch(error => {
            console.error("[IAG-ERROR] error collecting active devices: " + error.message)
            //enable reload button
            this.reloadButtonEnabled(true);
            //restart intervalReload
            this.intervalReloadEnabled(true);
        })
    }

    plotDataOnMap(activeDeviceData, firstLoad) {
        let trackingLatLng = null;
        let updatedDataset = this.state.longTermFeaturesDataset;
        let tmpActivityTypeArray = this.state.activityTypeArray;
        //loop all items
        for (let i = 0; i < updatedDataset.length; i++) {
            //blanking the coords
            updatedDataset[i].geometry.coordinates = [];
        }
        for (let j = 0; j < activeDeviceData.length; j++) {
            for (let i = 0; i < updatedDataset.length; i++) {
                if (updatedDataset[i].name.toLowerCase() === activeDeviceData[j].name.toLowerCase()) {
                    //get data array item in a local var
                    let data = activeDeviceData[j];
                    updatedDataset[i].properties.cellStrength = data.cellular_signal_strength;
                    //Add to activityTypeArray i
                    // let new activity type is found
                    if (data.activity_type.length > 0 && typeof data.activity_type[0].name !== "undefined") {
                        let deviceActivityTypeIndexes = [];
                        for (let k = 0; k < data.activity_type.length; k++) {
                            let matchFound = false;
                            for (let l = 0; l < tmpActivityTypeArray.length; l++) {
                                if (tmpActivityTypeArray[l].name === data.activity_type[k].name) {
                                    deviceActivityTypeIndexes.push(l);
                                    matchFound = true;
                                    break;
                                }
                            }

                            if (!matchFound) {
                                //pushes unmatched item to feature dataset device index array and activity type array
                                deviceActivityTypeIndexes.push(tmpActivityTypeArray.push({
                                    name: data.activity_type[k].name,
                                    include: true,
                                }) - 1);
                            }

                        }
                        //add color to each activity type
                        for (let k = 0; k < tmpActivityTypeArray.length; k++) {
                            tmpActivityTypeArray[k].color = this.getRandomColor(k, tmpActivityTypeArray.length).hsl;
                        }

                        //update state
                        updatedDataset[i].properties.activityType = deviceActivityTypeIndexes;
                    }
                    else
                    {
                        updatedDataset[i].properties.activityType= [0];
                        //updatedDataset[i].properties.activityType= 0;
                    }
                    //new property to report all the device faults and initialising as
                    updatedDataset[i].properties.deviceFaults=[];
                    //condition to check if the GPS device is broken
                    if(data.gps===null)
                    {
                        updatedDataset[i].properties.deviceFaults.push("GPS device fault");
                        break;
                    }
                    //condition to check if the GPS signal is not reachable
                    if(data.gps.lon === null || data.gps.lat === null)
                    {
                        updatedDataset[i].properties.deviceFaults.push("GPS signal lost");
                        break;
                    }
                    //check if lat / lons are null and if so, break
                    if (data.gps.lon === null || data.gps.lat === null) break;
                    //push all metrics to corresponding feature vars
                    updatedDataset[i].geometry.coordinates.push([data.gps.lon, data.gps.lat]);
                    updatedDataset[i].properties.lastPointUpdate = data.gps.time;
                    updatedDataset[i].properties.lastSpeed = data.gps.speed;
                    updatedDataset[i].properties.lastAlt = data.gps.alt;

                    //add speed item to the average speed array
                    updatedDataset[i].properties.avgSpeedArray.push(data.gps.speed);
                    //while the avg speed array > 10
                    while(updatedDataset[i].properties.avgSpeedArray.length > 180){
                        //remove the item at the first point of the queue
                        updatedDataset[i].properties.avgSpeedArray.shift();
                    }
                    if(data.gps.speed)
                    {
                        updatedDataset[i].properties.avgHectares.isoDate.push(data.gps.time);
                        updatedDataset[i].properties.avgHectares.speed.push(data.gps.speed);
                    }

                    //build the description for the popup
                    updatedDataset[i].properties.description = `<span>
                        <div><div class="div-circle" style='background-color: ${updatedDataset[i].properties.color}'></div> ${data.name}</div>
                        <div>Last seen moving <strong>${data.gps.speed? data.gps.speed.toFixed(2)+"km/h" :"-" }</strong> at an altitude of <strong>${data.gps.alt}m</strong> with <strong>${data.cellular_signal_strength}%</strong> cell strength</div>
                        <div><small>${timeSince(new Date(data.gps.time))} ago</small></div>
                    </span>`;

                    break;
                }
            }
        }

        //update activity types state
        this.setState({activityTypeArray: tmpActivityTypeArray}, () => {
            //then update featuresDataset and finally filter the results
            this.setFeaturesDatasetState(updatedDataset,()=>this.filterActivityType());
        });

        if (firstLoad === true) {
            // add it to the map
            for(let i=0;i<this.state.featuresDataset.length;i++)
            {

                this.state.map.addSource(`line-source-${this.state.featuresDataset[i].name}`, {
                    'type': 'geojson',
                    'data': {
                        "type": "Feature",
                        "properties": this.state.featuresDataset[i].properties,
                        "geometry": {
                            'type':'LineString',
                            'coordinates':this.state.featuresDataset[i].geometry.coordinates
                        }
                    }

                });
                this.state.map.addLayer({
                    'id': `line-layer-${this.state.featuresDataset[i].name}`,
                    'type': 'line',
                    'source': `line-source-${this.state.featuresDataset[i].name}`,
                    'paint': {
                        'line-width': 4,
                        'line-color': this.state.featuresDataset[i].properties.color
                    }
                });
            }

            this.initMapPopups();

            this.reloadButtonEnabled(true);
            //restart intervalReload
            this.intervalReloadEnabled(true);
        }


        //update online statuses
        this.checkOnlineStates();

        //get last points
        for (let i = 0; i < updatedDataset.length; i++) {
            let currentLastPoints = updatedDataset[i].geometry.coordinates[updatedDataset[i].geometry.coordinates.length - 1];
            if (typeof currentLastPoints !== 'undefined') {
                this.initDots({
                    color: updatedDataset[i].properties.color,
                    coords: currentLastPoints,
                    name: updatedDataset[i].name,
                    online: updatedDataset[i].properties.online
                });
            }
        }

        if (firstLoad === true) {
            // setup the viewport
            this.state.map.setPitch(35);
            //add zoom and rotation controls to the map.
            this.state.map.addControl(new mapboxgl.NavigationControl());
            for(let i=0;i<updatedDataset.length;i++)
            {
                if(updatedDataset[i].properties.online)
                {
                    this.locateDevice(updatedDataset[i].name)
                    break;
                }
            }
        } else {
            try {
                for(let i=0;i<this.state.longTermFeaturesDataset.length;i++) {
                    this.state.map.getSource(`line-source-${this.state.longTermFeaturesDataset[i].name}`).setData({
                        "type": "Feature",
                        "properties": this.state.featuresDataset[i].properties,
                        "geometry": {
                            'type': 'LineString',
                            'coordinates': this.state.longTermFeaturesDataset[i].geometry.coordinates
                        }
                    });
                }


            } catch (e) {
                console.error("[IAG-ERROR] error updating: " + e.message)
            }
        }

        if (trackingLatLng !== null) {
            this.state.map.jumpTo({
                'center': trackingLatLng,
                'zoom': 16
            });
        }

        //set map loaded state
        this.setState({mapLoaded: true});
        //enable reload button
        this.reloadButtonEnabled(true);
        //restart intervalReload
        this.intervalReloadEnabled(true);
    }

    initDots(item) {
        if (typeof this.state.map.getLayer(`layer-with-pulsing-dot-${item.name}`) === 'undefined') {
            this.state.map.addImage(`pulsing-dot-${item.name}`, this.getDot(item.color, item.online), {pixelRatio: 2});
            this.state.map.addSource(`dot-point-${item.name}`, {
                'type': 'geojson',
                'data': {
                    'type': 'FeatureCollection',
                    'features': [{
                        'type': 'Feature',
                        'geometry': {
                            'type': 'Point',
                            'coordinates': item.coords
                        }
                    }]
                }
            });
            this.state.map.addLayer({
                'id': `layer-with-pulsing-dot-${item.name}`,
                'type': 'symbol',
                'source': `dot-point-${item.name}`,
                'layout': {
                    'icon-image': `pulsing-dot-${item.name}`
                }
            });
        } else {
            this.state.map.getSource(`dot-point-${item.name}`).setData({
                'type': 'FeatureCollection',
                'features': [{
                    'type': 'Feature',
                    'geometry': {
                        'type': 'Point',
                        'coordinates': item.coords
                    }
                }]
            });
            this.state.map.removeImage(`pulsing-dot-${item.name}`);
            this.state.map.addImage(`pulsing-dot-${item.name}`, this.getDot(item.color, item.online), {pixelRatio: 2});
        }
    }

    getDot(color, pulsing) {
        let size = 65;

        // This implements `StyleImageInterface`
        // to draw a pulsing dot icon on the map.
        return {
            width: size,
            height: size,
            data: new Uint8Array(size * size * 4),

            // When the layer is added to the map,
            // get the rendering context for the map canvas.
            onAdd: function () {
                let canvas = document.createElement('canvas');
                canvas.width = this.width;
                canvas.height = this.height;
                this.context = canvas.getContext('2d');
            },

            map: this.state.map,
            // Call once before every frame where the icon will be used.
            render: function () {
                let radius = (size / 2) * 0.3;
                let context = this.context;

                if (pulsing === true) {
                    let duration = 1000;
                    let t = (performance.now() % duration) / duration;
                    let outerRadius = (size / 2) * 0.7 * t + radius;
                    // Draw the outer circle.
                    context.clearRect(0, 0, this.width, this.height);
                    context.beginPath();
                    context.arc(this.width / 2, this.height / 2, outerRadius, 0, Math.PI * 2);
                    context.fillStyle = color.replace(")", `, ${1 - t})`);
                    context.fill();
                }

                // Draw the inner circle.
                context.beginPath();
                context.arc(this.width / 2, this.height / 2, radius, 0, Math.PI * 2);
                context.fillStyle = color;
                context.strokeStyle = 'white';
                context.lineWidth = 3;//2 + 4 * (1 - t);
                context.fill();
                context.stroke();

                // Update this image's data with data from the canvas.
                this.data = context.getImageData(0, 0, this.width, this.height).data;
                // Continuously repaint the map, resulting
                // in the smooth animation of the dot.
                this.map.triggerRepaint();
                // Return `true` to let the map know that the image was updated.
                return true;
            }
        }
    }

    initMapPopups() {
        // Create a popup, but don't add it to the map yet.
        let popup = new mapboxgl.Popup({
            closeButton: false,
            closeOnClick: false
        });

        let self = this;


        for (let i=0; i<this.state.featuresDataset.length;i++)
        {
            this.state.map.on('mouseenter', 'line-layer-'+this.state.featuresDataset[i].name, function (e) {
                // Change the cursor style as a UI indicator.
                self.state.map.getCanvas().style.cursor = 'pointer';

                //let coordinates = e.features[0].geometry.coordinates.slice();
                let description = e.features[0].properties.description;

                // Populate the popup and set its coordinates
                // based on the feature found.
                popup.setLngLat([e.lngLat.lng, e.lngLat.lat]).setHTML(description).addTo(self.state.map);
            });

            this.state.map.on('mouseleave', 'line-layer-'+this.state.featuresDataset[i].name, function () {
                self.state.map.getCanvas().style.cursor = '';
                popup.remove();
            });
        }

    }

    checkOnlineStates() {
        let tmpFeaturesDataset = this.state.featuresDataset
        for (let i = 0; i < tmpFeaturesDataset.length; i++) {
            if (tmpFeaturesDataset[i].properties.lastPointUpdate !== null) {
                let diff = new Date() - new Date(tmpFeaturesDataset[i].properties.lastPointUpdate);
                tmpFeaturesDataset[i].properties.online = (diff < 120000);
            }
        }
        this.setFeaturesDatasetState(tmpFeaturesDataset,null,false);
    }

    addFeatureSetPoint(name, color, deviceId, displayName) {
        let tmp = this.state.featuresDataset;
        let tmpSize = tmp.push({
            'name': name.toLowerCase(),
            'type': 'Feature',
            'properties': {
                'color': color.hsl,
                'hexColor': color.hex,
                'lastPointUpdate': null,
                'lastSpeed': null,
                'description': null,
                'tracking': false,
                'deviceId': deviceId,
                'displayName': displayName,
                'avgSpeedArray': [],
                'avgHectares': {isoDate:[],speed:[]},
                'deviceFaults':[], //property to report device faults
                'activityType': [0]

            },
            'geometry': {
                'type': 'LineString',
                'coordinates': []
            }
        });
        this.setFeaturesDatasetState(tmp,null,false);

        return tmpSize;
    }


    componentDidMount() {
        this.setState({
            map: new mapboxgl.Map({
                container: this.mapContainer.current,
                style: 'mapbox://styles/mapbox/satellite-streets-v11',
                center: [this.state.lng, this.state.lat],
                zoom: this.state.zoom
            })
        }, () => {
            this.state.map.on('load', () => {
                //reload devices which in turn will activate the interval reload
                this.reloadDevices();
            });
        });
        //schedule tooltip update
        this.scheduleReloadTooltipUpdate = null;
        //set up the interval timer var
        this.intervalTimer = null;
        //set up interval
        //this.intervalReloadEnabled(true);
        //set up visibilityhook
        //this.initBindings();
    }

    componentWillUnmount() {
        this.intervalReloadEnabled(false);
    }

    initBindings(){
        let self = this;
        document.addEventListener("visibilitychange", function() {
            if(document.hidden) {
                self.intervalReloadEnabled(false)
            }else {
                self.intervalReloadEnabled(true);
            }
        });
    }

    intervalReloadEnabled(enable){
        clearInterval(this.intervalTimer);
        if(enable){
            this.intervalTimer = setInterval(
                () => this.intervalReload(),
                40000
            );
        }
    }

    intervalReload() {
        //set button to disabled
        this.reloadButtonEnabled(false);
        //disable intervalReload
        this.intervalReloadEnabled(false);
        //load and process the matched and active devices
        this.loadActiveDevices();
    }

    reloadDevices() {
        this.reloadButtonEnabled(false);
        //disable intervalReload
        this.intervalReloadEnabled(false);
        if (this.props.farmsData !== null && this.props.activeFarm !== null) {
            //call out to grab the devices
            this.props.axios({
                url: apiConfig.endpoints.devices.getAll,
                params: {
                    deviceType: "AssetTracker",
                    farmId: this.props.farmsData[this.props.activeFarm].id
                }
            }).then(result => {
                //clear selected devices
                this.clearSelectedDevice();
                //reset featuresDataset (contains all points and properties) and alreadyInitd (for map init) to default values and once complete
                this.setState({alreadyInitd: false}, () => {
                    this.setFeaturesDatasetState([], () => {
                        //on response, loop devices
                        result.data.map((device, index) => {
                            //and add each to the blank featureset
                            return this.addFeatureSetPoint(device.name, this.getRandomColor(index, result.data.length), device.id, device.display_name);
                        });
                        //load and process the matched and active devices
                        this.loadActiveDevices();
                    });
                });
            });
        }
    }

    render() {
        return (
            <div id={"mapdotjs-container"} className={"h-100"}>
                <div id={"sidebar-detail-outer"} className={"vh-100"}>
                    <div className={"flex-column container border-right shadow" + (this.state.sidebarDetailCollapsed ? "" : " toggled")} id="sidebar-detail-wrapper">
                        <Row id={"sidebar-detail-search-outer"}>
                            <Col>
                                <h2 className={"pt-2"}>Devices</h2>
                            </Col>
                            {this.state.reloadButtonEnabled === true
                                ? <Col id={"reloadButton"} className={"text-right pt-3 pointer"}
                                       onClick={this.reloadDevices}>
                                    <FontAwesomeIcon icon={"redo-alt"}/>
                                </Col>
                                : <Col id={"reloadButton"} className={"text-right pt-3"}>
                                    <div className="loading-dots">
                                        <div className="loading-dots--dot"></div>
                                        <div className="loading-dots--dot"></div>
                                        <div className="loading-dots--dot"></div>
                                    </div>
                                </Col>
                            }
                        </Row>

                        <Tooltip placement="right" className={"reloadTooltipClass"} isOpen={this.state.tooltipReloadToggled} target="reloadButton"
                                 toggle={this.toggleReloadButton}>
                                    {this.state.reloadButtonEnabled === true
                                        ? "This screen reloads automatically, reload anyway?"
                                        : "Reloading devices..."}
                        </Tooltip>

                        <Row>
                            <Col>
                                <SearchBox persistentItems={this.state.longTermFeaturesDataset} setFilterableItems={this.setFeaturesDatasetStateForSearch} filterOn={["name", "properties.displayName"]} enabled={this.state.reloadButtonEnabled} />
                            </Col>
                        </Row>
                        <Row className={"pt-1 pb-1"}>
                            <Col>
                                {this.state.activityTypeArray.map((activityTypeItem, index) =>
                                    this.state.activityTypeArray[index].include
                                        ?
                                        <button key={index} className={"btn badge badge-pill btn-primary-outline mr-1"} value={activityTypeItem.name} disabled={!this.state.reloadButtonEnabled}  onClick={e => this.setFeaturesDatasetStateForSearchButtons(e,index)} style={{backgroundColor: activityTypeItem.color,color: "white"}}>
                                            {activityTypeItem.name}
                                        </button>
                                        :
                                        <button key={index} className={"btn badge badge-pill btn-primary-outline mr-1"} value={activityTypeItem.name}  disabled={!this.state.reloadButtonEnabled}  onClick={e => this.setFeaturesDatasetStateForSearchButtons(e,index)} style={{color: activityTypeItem.color, borderColor: activityTypeItem.color}}>
                                           {activityTypeItem.name}
                                        </button>
                                )}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <DeviceTrailDropdown setCurrentDuration={this.setCurrentDuration} reloadDevices={this.reloadDevices} enabled={this.state.reloadButtonEnabled} ></DeviceTrailDropdown>
                            </Col>
                        </Row>

                        <Row className={"y-auto x-hidden flex-grow-1"} id={"sidebar-detail-inner-row"}>
                            <Col>
                                {this.props.farmsData !== null && this.props.activeFarm !== null
                                    ? this.state.devicesError === null
                                        ? this.state.featuresDataset === null
                                                ? <div>Collecting your devices...</div>
                                                : this.state.featuresDataset.length > 0
                                                    ? this.state.featuresDataset.map((device, index) => <DeviceCard key={index}
                                                        device={device} locateDevice={this.locateDevice} editable={true}
                                                        editOnClickHandler={this.editDevice} cardIndex={index} activityType={this.state.activityTypeArray}/>)
                                                    : <div className={"mt-2"}>No devices found, please <a href="mailto:support@informag.com.au">contact support</a> for further assistance.</div>

                                        : <div>Something bad happened: {this.state.devicesError}
                                            <button onClick={() => this.reloadDevices()}>Retry</button>
                                        </div>
                                    : "No farms selected"}
                            </Col>
                        </Row>
                    </div>
                </div>

                {this.state.selectedDeviceIndex !== null
                    ? <Modal isOpen={this.state.editDeviceModalIsOpen}
                           toggle={() => this.setEditDeviceModalIsOpen(false)}>
                        <ModalHeader
                            toggle={() => this.setEditDeviceModalIsOpen(false)}>Edit {this.state.featuresDataset[this.state.selectedDeviceIndex].properties.displayName ?? this.state.featuresDataset[this.state.selectedDeviceIndex].name}</ModalHeader>
                        <ModalBody>
                            <Form id={"edit-device-form"}>
                                <FormGroup>
                                    <Label for="display-name-input">Display Name</Label>
                                    <Input className={"form-control"} name="display-name-input" id="display-name-input"
                                           placeholder="Enter your preferred display name"
                                           defaultValue={this.state.featuresDataset[this.state.selectedDeviceIndex].properties.displayName ?? ""}/>
                                    <Input name="device-id" id="device-id" type={"hidden"}
                                           defaultValue={this.state.featuresDataset[this.state.selectedDeviceIndex].properties.deviceId}/>
                                </FormGroup>
                            </Form>
                        </ModalBody>
                        <ModalFooter>
                            <Button color={"light"}
                                    onClick={() => this.setEditDeviceModalIsOpen(false)}>Cancel</Button>{' '}
                            <Button color={"primary"} onClick={this.patchDeviceDisplayName}
                                    disabled={this.state.savingDevice}>
                                {this.state.savingDevice
                                    ? <div className="loading-dots">
                                        <div className="loading-dots--dot"></div>
                                        <div className="loading-dots--dot"></div>
                                        <div className="loading-dots--dot"></div>
                                    </div>
                                    : "Save Changes"
                                }</Button>
                        </ModalFooter>
                    </Modal>
                    : ""
                }

                <TogglePanel hidden={this.state.togglePanelCollapsed} title={"Today's Metrics"} isLoading={!this.state.reloadButtonEnabled} actionBtnHandler={() => {this.reloadDevices()}} actionBtnIcon={"redo-alt"}>
                    {this.props.farmsData !== null && this.props.activeFarm !== null?
                    <TodaysMetricChartsTab mapLoaded={this.state.mapLoaded} locateDevice={this.locateDevice} longTermFeaturesDataset={this.state.longTermFeaturesDataset} farmId={this.props.farmsData[this.props.activeFarm].id} reloadButtonEnabled={this.state.reloadButtonEnabled} ></TodaysMetricChartsTab>
                        :""}
                </TogglePanel>

                <div ref={this.mapContainer} className="map-container h-100"/>

                <div className={"toggle-panel-toggle"} onClick={this.toggleTogglePanel}>
                    <FontAwesomeIcon
                        icon={"chart-line"}/>
                </div>

                <div className={"sidebar-detail-toggle"} onClick={this.toggleSidebar}>
                    <FontAwesomeIcon
                        icon={(this.state.sidebarDetailCollapsed ? "angle-double-right" : "angle-double-left")}/>
                </div>
            </div>
        );
    }
});
