import React from 'react';
import "./Loading.css";

export default function (){
    return(
        <div id={"loading-container"} className={"text-center"}>
            <img src="images/logos-03.png" width="280" alt={"InformAg Logo"} />
            <div className='pulse-container'>
                <svg className="pulse pulse_out_1" xmlns="http://www.w3.org/2000/svg">
                    <circle className="back" cx="200" cy="200" r="190"/>
                    <circle className="front" cx="200" cy="200" r="190"/>
                </svg>
    
                <svg className="pulse pulse_out_2" xmlns="http://www.w3.org/2000/svg">
                    <circle className="back" cx="200" cy="200" r="190"/>
                    <circle className="front" cx="200" cy="200" r="190"/>
                </svg>
    
                <svg className="pulse pulse_out_3" xmlns="http://www.w3.org/2000/svg">
                    <circle className="back" cx="200" cy="200" r="190"/>
                    <circle className="front" cx="200" cy="200" r="190"/>
                </svg>
    
                <svg className="pulse pulse_out_4" xmlns="http://www.w3.org/2000/svg">
                    <circle className="back" cx="200" cy="200" r="190"/>
                    <circle className="front" cx="200" cy="200" r="190"/>
                </svg>
    
                <svg className="pulse pulse_out_5" xmlns="http://www.w3.org/2000/svg">
                    <circle className="back" cx="200" cy="200" r="190"/>
                    <circle className="front" cx="200" cy="200" r="190"/>
                </svg>
    
                <svg className="pulse pulse_out_6" xmlns="http://www.w3.org/2000/svg">
                    <circle className="back" cx="200" cy="200" r="190"/>
                    <circle className="front" cx="200" cy="200" r="190"/>
                </svg>
    
                <svg className="pulse pulse_out_7" xmlns="http://www.w3.org/2000/svg">
                    <circle className="back" cx="200" cy="200" r="190"/>
                    <circle className="front" cx="200" cy="200" r="190"/>
                </svg>
    
                <svg className="pulse pulse_out_8" xmlns="http://www.w3.org/2000/svg">
                    <circle className="back" cx="200" cy="200" r="190"/>
                    <circle className="front" cx="200" cy="200" r="190"/>
                </svg>
    
                <svg className="satellite" width="253" height="259" viewBox="0 0 253 259" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="176.52" y="147.291" width="103.632" height="53.9308" rx="25" transform="rotate(44.9401 176.52 147.291)" fill="#aa9d91"/>
                    <rect x="43.9938" y="14.755" width="103.632" height="53.9308" rx="25" transform="rotate(44.9401 43.9938 14.755)" fill="#aa9d91"/>
                    <rect x="142.37" y="86.4256" width="50.9136" height="87.8906" rx="25.4568" transform="rotate(44.9401 142.37 86.4256)" fill="#9d8e80" fillOpacity="0.48"/>
                    <rect x="137.918" y="80.1035" width="48.8188" height="86.1409" rx="24.4094" transform="rotate(44.9401 137.918 80.1035)" stroke="black" strokeWidth="7"/>
                    <rect x="174.27" y="138.706" width="103.124" height="50.3094" rx="21.5" transform="rotate(44.9401 174.27 138.706)" stroke="black" strokeWidth="7"/>
                    <line x1="146.735" y1="145.411" x2="232.294" y2="230.971" stroke="black" strokeWidth="7"/>
                    <rect x="78.6332" y="114.065" width="103.124" height="50.3094" rx="21.5" transform="rotate(-135.06 78.6332 114.065)" stroke="black" strokeWidth="7"/>
                    <line x1="105.722" y1="107.227" x2="20.1624" y2="21.6675" stroke="black" strokeWidth="7"/>
                    <line x1="61.1746" y1="20.9604" x2="23.6979" y2="58.4371" stroke="black" strokeWidth="7"/>
                    <line x1="100.065" y1="59.8513" x2="61.8817" y2="98.0351" stroke="black" strokeWidth="7"/>
                    <line x1="80.9736" y1="40.7594" x2="42.0827" y2="79.6503" stroke="black" strokeWidth="7"/>
                    <line x1="193.404" y1="156.018" x2="155.22" y2="194.202" stroke="black" strokeWidth="7"/>
                    <line x1="231.587" y1="195.616" x2="194.111" y2="233.092" stroke="black" strokeWidth="7"/>
                    <line x1="213.203" y1="175.817" x2="174.312" y2="214.708" stroke="black" strokeWidth="7"/>
                    <line x1="141.078" y1="112.177" x2="124.814" y2="128.441" stroke="black" strokeWidth="7" strokeLinejoin="round"/>
                    <line x1="114.915" y1="138.34" x2="109.258" y2="143.997" stroke="black" strokeWidth="7"/>
                </svg>
    
    
            </div>
        </div>

    );
}