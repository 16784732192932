import React, { Component } from 'react';
import {apiConfig} from "../apiConfig";
import moment from "moment";
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, CardTitle, CardText, Row, Col } from 'reactstrap';
import BarChart from "./BarChart";
import classnames from 'classnames';
import withAxios from "react-axios/lib/components/withAxios";
export default withAxios(class TodaysMetricChartsTab extends Component {
    constructor(props) {
        super(props);
              this.state = {
            barchartData: {data: [], colors: [], ids: []},
            barchartIsoDate:"",
            barchartLoadDate:"",
            barchartDataForHectareSpeed: {data: [], colors: []},
                  activeTab:'1',
                  firstLoad:true, //first load of the page
                  chartLoad:true, //end of the loadchart function for the first time
        };
        this.toggle = this.toggle.bind(this);
        this.loadChartData=this.loadChartData.bind(this);
        this.setFirstLoad=this.setFirstLoad.bind(this);
        this.setChartLoad=this.setChartLoad.bind(this);
        this.loadChartDataforHectareSpeed=this.loadChartDataforHectareSpeed.bind(this);
    }
    setFirstLoad(firstload) {
        //first set the firstload false and load the Hectare chart
        this.setState({firstLoad: firstload},()=>this.loadChartData());
    }
    setChartLoad(chartLoad){
        this.setState({chartLoad});
    }
    componentDidUpdate(prevProps, prevState) {
        //reload chart on the reload button click
        if (this.props.reloadButtonEnabled !== prevProps.reloadButtonEnabled && (!this.props.reloadButtonEnabled)  && this.props.mapLoaded) {
            this.toggle(this.state.activeTab);
        }
        //to load the function for the first time
        if(this.state.firstLoad && this.props.mapLoaded)
            this.setFirstLoad(false);
    }
    //function for toggling between the tabs
    toggle(tab){
        if(this.state.activeTab !== tab)
        {
            this.setState({activeTab: tab});
        }
        //always refresh the data
        if(tab==='2')
        {
            //load the Speed chart
            this.loadChartDataforHectareSpeed();
        }
        else
        {
            //load the hectares covered chart
            this.loadChartData();
        }

    }

    //function to load speed chart
    loadChartDataforHectareSpeed(){
        //define a temp array
        let barchartDataForHectareSpeed={data: [], colors: [], ids:[]};
        //get all the colours from the previous chart
        for(let i=0; i<this.state.barchartData.colors.length;i++)
        {
            barchartDataForHectareSpeed.colors.push(this.state.barchartData.colors[i]);
            barchartDataForHectareSpeed.ids.push(this.state.barchartData.ids[i]);
        }
        //define DeviceName array
        let nameArray = ["Device"];
        //define Speed array
        let dataArray = ["Speed"];
        //loop through each device in the featured dataset
        for(let i=0; i< this.props.longTermFeaturesDataset.length;i++)
        {
            //get the current device
            let currentDevice=this.props.longTermFeaturesDataset[i];
            let total=0;
            let outputHectarePerHalfHour = "";
            //loop through all the speeds for the current device and sum
            let j=currentDevice.properties.avgHectares.isoDate.length-1;
            //total points taken for the average calculation
            let totalPoints=0;
            let currentDateTime=moment(new moment(new Date()).utc()).format();
            //check if the current device has data for the last 30 mins using the last update date
            if(currentDevice.properties.avgHectares.isoDate.length>0 && ((Date.parse(currentDateTime)-Date.parse(currentDevice.properties.lastPointUpdate))<1800000))
            {
                //take the last 30 mins of ISO data and add the speed for average calculation
                while((Date.parse(currentDevice.properties.avgHectares.isoDate[currentDevice.properties.avgHectares.isoDate.length-1]))-(Date.parse(currentDevice.properties.avgHectares.isoDate[j]))<=1800000)                {
                    total = total + currentDevice.properties.avgHectares.speed[j];
                    totalPoints++;
                    j--;
                }
            }

            let averageSpeedKmh = (total / totalPoints).toFixed(2);
            //set average row width
            let rowWidth = 3.7;
            //convert kilometres per hour to metres per hour
            let averageMetresPerHour = averageSpeedKmh * 1000;
            //work out metres per square hour
            let averageMetresSquaredPerHour = averageMetresPerHour * rowWidth;
            //divide that into a hectare in metres
            let squareMetresPerHectare = averageMetresSquaredPerHour / 10000;
            //save to output string to two decimals
            outputHectarePerHalfHour = (squareMetresPerHectare).toFixed(2);
            //push both device name and calculated speed to the respective arrays
            nameArray.push(currentDevice.name);
            dataArray.push(outputHectarePerHalfHour);
        }
        //puth both arrays to a single array
        let barchartData = [nameArray, dataArray];
        //add it to the data
        barchartDataForHectareSpeed.data=barchartData;
        //set the state to update the chart
        this.setState({barchartDataForHectareSpeed: barchartDataForHectareSpeed});
    }

    //function to find the device index id
    findDeviceId(deviceName){
        let dataset =  this.props.longTermFeaturesDataset;
        if (dataset !== null) {
            for (let i = 0; i < dataset.length; i++) {
                if (dataset[i].name.toLowerCase() === deviceName.toLowerCase()) {
                    return i;
                }
            }
        }
        return false;
    }

    //function to load hectares covered chart
    loadChartData() {
        //get current day and set time to 00:00
        let startDateTime = new Date();
        startDateTime.setHours(0,0,0,0);
        //run axios call to grab the hectares covered data
        this.props.axios({
            url: apiConfig.endpoints.getHectareCovered.getAll,
            params: {
                farmId: this.props.farmId,
                //if its the first call send the UTC startdatetime else send the last ISO date
                isoDate: this.state.barchartIsoDate==="" ? moment(new moment(startDateTime).utc()).format('MM/DD/YYYY hh:mm:ss A') :this.state.barchartIsoDate
            }
        }).then(result => {
            //temp array for storing the data
            let barchartData = this.state.barchartData.data;
            let barchartColors = this.state.barchartData.colors;
            let barchartIds = this.state.barchartData.ids;
            //check if the current state driven barchart data is empty or the saved date is different from today
            if (barchartData.length === 0 || this.state.barchartLoadDate !== new Date().toDateString()) {
                //set the stae of the barchart load date
                this.setState({barchartLoadDate: new Date().toDateString()});
                //if its the first API call, set up the data arrays
                let nameArray = ["Device"];
                let dataArray = ['Hectares'];
                //loop results
                for (let i = 0; i < result.data.length; i++) {
                    //find device and match to current dataset
                    let deviceIndex = this.findDeviceId(result.data[i].data.name);
                    //providing its not false
                    if (deviceIndex !== false) {
                        //push the display or name to the array
                        nameArray.push(this.props.longTermFeaturesDataset[deviceIndex].properties.displayName ?? result.data[i].data.name);
                        //push the display or name to the array
                        barchartIds.push(result.data[i].data.name);
                        //add the data to the array
                        dataArray.push(result.data[i].data.hectares_covered);
                        //push colour to the array
                        barchartColors.push(this.props.longTermFeaturesDataset[deviceIndex].properties.hexColor)
                    }
                }
                //store back to barchart data var
                barchartData = [nameArray, dataArray];
            } else {
                //else run two loops and add the new hectare covered to the particular device
                for (let i = 0; i < result.data.length; i++) {
                    //loop for existing barchart data
                    for (let j = 1; j < barchartData[1].length; j++) {
                        //check if device name is same
                        if (barchartIds[j-1] === result.data[i].data.name) {
                            //add the result
                            barchartData[1][j] = result.data[i].data.hectares_covered + barchartData[1][j];
                            //break if found for efficiency
                            break;
                        }
                    }
                }

            }
            //set state variable in react which will update the chart
            this.setState({barchartData: {data: barchartData, colors: barchartColors, ids: barchartIds}});
            //set last iso date for the next api call
            this.setState({barchartIsoDate: result.data[0].last_isodate},()=>this.setChartLoad(false));
            //this.setBarchartIsoTime(result.data[0].last_isodate);

        }).catch(error => {
            console.error("[IAG-ERROR] error loading the chart:," + error.message)
        });
    }
    render () {
        return (
            <div className={"h-100"}>
                <Nav tabs>
                    <NavItem>
                        <NavLink
                            disabled={!this.props.reloadButtonEnabled}
                            className={classnames({ active: this.state.activeTab === '1' }) + " pointer"}
                            onClick={() => { this.toggle('1');  }}
                            onLoad = {() => { this.toggle('1');}}
                        >
                            Total Ha.
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            disabled={!this.props.reloadButtonEnabled ||this.state.chartLoad}
                            className={classnames({ active: this.state.activeTab === '2' }) + " pointer"}
                            onClick={() => { this.toggle('2'); }}
                        >
                            Ha. per Hr.
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={this.state.activeTab} className={"p-2"} style={{backgroundColor: "#ffffff", borderLeft: "1px", borderRight: "1px", borderLeftColor: "#dee2e6", borderRightColor: "#dee2e6", borderLeftStyle: "solid", borderRightStyle: "solid" }}>
                    <TabPane tabId="1">
                        { this.state.activeTab === '1' ?
                        <Row>
                            <Col style={{height:"inherit"}} sm="12">
                                <Card  id={"refHeight"} body className={"h-100"}>
                                    <CardTitle tag="h5">Total Hectares Covered Today</CardTitle>
                                    <CardText>
                                        <small className="text-muted">Showing the total hectares covered per device, since midnight local time.</small>
                                    </CardText>
                                    <CardText  className={"h-100"}>

                                        <BarChart locateDevice={this.props.locateDevice} barchartData={this.state.barchartData} sHeight={window.innerHeight*0.6} ></BarChart>
                                    </CardText>
                                </Card>

                            </Col>
                        </Row>:""}
                    </TabPane>
                    <TabPane tabId="2" className={"p2"}>
                        { this.state.activeTab === '2'?
                            <Row>
                            <Col style={{height:"inherit"}} sm="12">
                                <Card body className={"h-100"}>
                                    <CardTitle tag="h5">Hectares per Hour</CardTitle>
                                    <CardText>
                                        <small className="text-muted">Showing the hectares per hour, for the last 30 minutes.</small>
                                    </CardText>
                                    <CardText className={"h-100"}>

                                        <BarChart locateDevice={this.props.locateDevice} barchartData={this.state.barchartDataForHectareSpeed} sHeight={window.innerHeight*0.6} ></BarChart>
                                    </CardText>
                                </Card>
                            </Col>
                        </Row>:""}
                    </TabPane>
                </TabContent>
            </div>
        );
    }
});
