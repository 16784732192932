import React, { useState } from 'react';
import {Dropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap';
import {pca} from "../index";
import { useMsal } from "@azure/msal-react";
import "./UserDropdown.css"

const UserDropdown = (props) => {
    const { instance } = useMsal();
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggle = () => setDropdownOpen(prevState => !prevState);

    return (
        <Dropdown isOpen={dropdownOpen} toggle={toggle} className={"userDropdown text-right"}>
            <DropdownToggle color={"primary"} caret>
                {pca.getActiveAccount().name}
            </DropdownToggle>
            <DropdownMenu right>
                <DropdownItem header>{pca.getActiveAccount().username}</DropdownItem>
                {/*<DropdownItem>Stuff One</DropdownItem>
                <DropdownItem>Stuff Two</DropdownItem>*/}
                <DropdownItem divider />
                <DropdownItem onClick={() => instance.logout()}>Logout</DropdownItem>
            </DropdownMenu>
        </Dropdown>
    );
}

export default UserDropdown;