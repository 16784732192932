import React  from 'react';
import "./TogglePanel.css"
import {Col, Row} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const TogglePanel = (props) => {
    return (
        <div id={"toggle-panel"} className={"vh-100 border-right shadow" + (props.hidden ? "" : " toggled")}>
            <Row className={"position-relative"}>
                <Col xs={10}>
                    <h2 className={"pt-2"}>{props.title}</h2>
                </Col>

                {typeof props.actionBtnHandler !== "undefined" ?
                    !props.isLoading
                        ? <Col className={"text-right pt-3 pointer"}
                                onClick={props.actionBtnHandler}>
                                <FontAwesomeIcon icon={props.actionBtnIcon}/>
                            </Col>
                        : <Col className={"text-right pt-3 pl-0"}>
                            <div className="loading-dots">
                                <div className="loading-dots--dot"></div>
                                <div className="loading-dots--dot"></div>
                                <div className="loading-dots--dot"></div>
                            </div>
                        </Col>
                    : ""
                }

            </Row>
            <Row className={"main-content"}>
                <Col>
                    {props.children}
                </Col>
            </Row>
        </div>
    );
}

export default TogglePanel;
